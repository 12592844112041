import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { Auth, API } from 'aws-amplify';
import { MdEdit } from 'react-icons/md';
import { IconContext } from "react-icons";
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import { RIEInput, RIETags, RIESelect } from '@attently/riek';
import Picklists from '../components/Picklists';
import Toggle from 'react-bootstrap-toggle';
import Select from 'react-select';
import { toast } from 'react-toastify';
import md5 from 'md5';
import 'react-toastify/dist/ReactToastify.css';

import _ from 'lodash';
// import axios from 'axios';
import moment from 'moment';

import "../css/Tables.css";
import "../css/Toast.css";

export default class Tables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      columns: [],
      description: '',
      parameters: [],
      modifytime: 0,
      isLoading: true,
      isDirty: false,
      objectComment: '',
      comments: [],
      sources: [{id: '1', text: 'Not defined'}, {id: '2', text: 'Trellis'}, {id: '3', text: 'UAccess Student'}]
    };
  }

  componentWillMount() {
    this.updateCallback = this.updateCallback.bind(this);
    this.onActiveToggle = this.onActiveToggle.bind(this);
    this.onRecordTypeChange = this.onRecordTypeChange.bind(this);
    this.getTable = this.getTable.bind(this);
    this.getComments = this.getComments.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.renderComments = this.renderComments.bind(this);
  }

  async componentDidMount() {
    try {
      const table = await this.getTable();
      const { name, columns, description, parameters, modifytime } = table;
      this.setState({ name, columns, description, parameters, modifytime });
      this.setState({ isLoading: false });
      const comments = await this.getComments(md5(this.state.name));
      this.setState({ comments: comments.comments });
    } catch (e) {
      alert(e);
    }
    // console.log("*** inside componentDidMount: state = " + JSON.stringify(this.state));
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.isDirty) {
      this.setState({ isDirty: false });
      this.setState({ isLoading: true });
      const table = await this.getTable();
      const { name, columns, description, parameters, modifytime } = table;
      this.setState({ name, columns, description, parameters, modifytime });
      this.setState({ isLoading: false });
    }
  }

  getTable() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("tables", `/tables/${this.props.match.params.name}`, myInit);
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error.response);
      return false;
    });
  }

  // get comments for an object or field
  getComments(id) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("comments", `/comments/${id}`, myInit);
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error.response);
      return false;
    });
  }

  handleCommentChange(event) {
      this.setState({objectComment: event.target.value});
  }

  // post a comment for an object
  async postComment(name, comment) {
    // event.preventDefault();
    await Auth.currentSession()
    .then(async function(session) {
      // console.log("*** inside postComment 1: name = " + name);
      let id = md5(name);
      // console.log("*** inside postComment 2: comment = " + comment);
      let updateObj = {
        text: comment
      };
      // console.log("*** inside postComment 3");
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateObj)
      };
      // console.log("*** inside postComment 4");
      return await API.post("comments", `/comments/${id}`, myInit)
      .then(async function(res) {
        // console.log("*** postComment res = " + JSON.stringify(res));
        myInit = { // OPTIONAL
          headers: {
            Authorization: token
          }
        };
        let updatedComments = await API.get("comments", `/comments/${id}`, myInit)
          .then(res => {
            // console.log("==== returned from getComments inside postComment ==== ");
            return res;
          });
        // console.log("*** comments after update = " + JSON.stringify(updatedComments));
        // this.setState({ comments: updatedComments });
        // this.setState({ objectComment: newState[keys[0]] });
        return updatedComments;
      })
    })
    .then(res => {
      // let objectComment = this.state.objectComment;
      // objectComment = false;
      // console.log("*** got updated comments: " + JSON.stringify(res));
      this.setState({ comments: res.comments });
      this.setState({ objectComment: ''});
    })
    .catch(error => {
      // console.log("Error in Auth.currentSession: " + JSON.stringify(error));
      return false;
    });
  }

  // update active state of object (fired by toggle switch)
  async onActiveToggle() {
    let newActiveState = (this.state.parameters.active === "Y") ? "N" : "Y";
    let updateObj = {
      type: 'obj',
      parameter: 'active',
      value: newActiveState
    };
    return await Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateObj)
      };
      API.post("tables", `/tables/${this.state.name}`, myInit)
      .then(res => {
        let parameters = Object.assign({}, this.state.parameters);
        parameters.active = newActiveState;
        this.setState({ parameters });
        return res;
      });
    })
    .catch(error => {
      console.log("Error updating table: " + error);
      return false;
    });
  }

  // update applicable record types on a single field
  async onRecordTypeChange(opt, colname) {
    let fieldRecordTypes = opt.map(
      (rectype) => {
        return (rectype.value);
      }
    );
    return await Auth.currentSession().then(session => {
      let updateObj = {
        type: 'field',
        parameter: 'recordtypes',
        column: colname,
        value: fieldRecordTypes
      };
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateObj)
      }
      API.post("tables", `/tables/${this.state.name}`, myInit)
      .then(res => {
        toast.success("Saved!");
        let columns = this.state.columns;
        // console.log("***** columns = " + JSON.stringify(columns));
        let colIdx = _.findIndex(columns, function(c) { return c.name === colname; });
        columns[colIdx].attrs.userDefined.recordtypes = fieldRecordTypes;
        this.setState({ columns });
        return res;
      });
    })
    .catch(error => {
      console.log("Error updating table: " + error);
      return false;
    });
  }

  // update callback used for all editable items (except active toggle)
  async updateCallback(newState) {
    const keys = Object.keys(newState);
    let updateObj = {}
    let strArr = [];
    let strArrClean = [];
    let keyComponents = keys[0].split("-");
    let isString = false;
    if (_.indexOf(keyComponents, "string") !== -1) {
      isString = true;
      strArr = newState[keys[0]].split(",");
      for(var i = 0; i < strArr.length; i++) {
        let clean_text = strArr[i].replace(/^\s*/, "").replace(/\s*$/, "");
        strArrClean.push(clean_text);
      }
    }
    updateObj = {
      type: keyComponents[0],
      parameter: keyComponents[1]
    };
    if (keyComponents[0] === 'field') {
      updateObj['column'] = keyComponents[2];
    }
    updateObj['value'] = (isString) ? strArrClean : newState[keys[0]];
    return await Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateObj)
      }
      API.post("tables", `/tables/${this.state.name}`, myInit)
      .then(res => {
        // update state to reflect changes
        if (keyComponents[0] === 'obj') {
          switch (keyComponents[1]) {
            case 'description':
              this.setState({ description: newState[keys[0]] });
              break;
            case 'tags':
              let parameters = Object.assign({}, this.state.parameters);
              parameters.tags = (isString) ? strArrClean : newState[keys[0]];
              this.setState({ parameters });
              break;
            default:
              break;
          }
        } else {
          let columns = this.state.columns;
          // console.log("***** columns = " + JSON.stringify(columns));
          let colIdx = _.findIndex(columns, function(c) { return c.name === keyComponents[2]; });
          columns[colIdx].attrs.userDefined[keyComponents[1]] = (isString) ? strArrClean : newState[keys[0]];
          this.setState({ columns });
        }
        toast.success("Saved!");
        // this.setState({ isDirty: true });
        return res;
      });
    })
    .catch(error => {
      console.log("Error updating table: " + error);
      return false;
    });
  };

  renderColumnDetail(columnDetail) {
    let tagsElem = null;
    let recordTypesElem = null;

    // Tags
    if (columnDetail.attrs.userDefined.tags.length === 0) {
      tagsElem = (
        <RIEInput
          value=""
          defaultValue = { this.props.updateOk ? "Click to enter comma-separated tags" : "No tags" }
          change={this.updateCallback}
          propName={"field-tags-" + columnDetail.name + "-string"}
          className={"riek-text-input-field"}
          classEditing={"riek-text-input-field-editing"}
          validate={_.isString}
          classLoading="loading"
          classInvalid="invalid"
          isDisabled={ this.props.updateOk ? false : true } />
      );
    } else {
      let tag_array = [];
      let _tags = columnDetail.attrs.userDefined.tags;
      for(let i = 0; i < _tags.length; i++) {
        let clean_text = _tags[i].replace(/^\s*/, "").replace(/\s*$/, "");
        tag_array.push(clean_text);
      }
      tagsElem = (
        <RIETags
          value={tag_array}
          change={this.updateCallback}
          maxTags={20}
          minTags={0}
          propName={"field-tags-" + columnDetail.name + "-array"}
          placeholder="New"
          className={"tags-field"}
          classLoading="loading"
          classEditing={"riek-text-input-field-editing"}
          isDisabled={ this.props.updateOk ? false : true } />
      );
    }

    // Record Types
    let fieldRecordTypes = false;
    if (columnDetail.attrs.userDefined.recordtypes.length > 0) {
      if (columnDetail.attrs.userDefined.recordtypes.length === 1 &&
        columnDetail.attrs.userDefined.recordtypes[0] === "__default__") {
        fieldRecordTypes = columnDetail.objectRecordTypes
      } else {
        fieldRecordTypes = columnDetail.attrs.userDefined.recordtypes.map(
          (rectype) => {
            return ({
              value: rectype,
              label: rectype
            });
          }
        );
      }
      // console.log("*** fieldRecordTypes = " + JSON.stringify(fieldRecordTypes));
    }
    if (columnDetail.objectRecordTypes.length > 0) {
      recordTypesElem = (
        <Select
          isMulti={true}
          value={fieldRecordTypes}
          onChange={(opt) => this.onRecordTypeChange(opt, columnDetail.name)}
          options={columnDetail.objectRecordTypes}
          className="basic-multi-select"
          classNamePrefix="select"
          isDisabled={ this.props.updateOk ? false : true }
        />
      );
    }
    // if (columnDetail.attrs.userDefined.recordtypes.length === 0) {
    //   recordTypesElem = (
    //     <RIEInput
    //       value=""
    //       defaultValue = "Click to enter comma-separated record types"
    //       change={this.updateCallback}
    //       propName={"field-recordtypes-" + columnDetail.name + "-string"}
    //       className={"riek-text-input-field"}
    //       classEditing={"riek-text-input-field-editing"}
    //       validate={_.isString}
    //       classLoading="loading"
    //       classInvalid="invalid"
    //       isDisabled={false} />
    //   );
    // } else {
    //   let tag_array = [];
    //   let _tags = columnDetail.attrs.userDefined.recordtypes;
    //   for(let i = 0; i < _tags.length; i++) {
    //     let clean_text = _tags[i].replace(/^\s*/, "").replace(/\s*$/, "");
    //     tag_array.push(clean_text);
    //   }
    //   recordTypesElem = (
    //     <RIETags
    //       value={tag_array}
    //       change={this.updateCallback}
    //       maxTags={20}
    //       minTags={0}
    //       propName={"field-recordtypes-" + columnDetail.name + "-array"}
    //       placeholder="New"
    //       className={"tags-field"}
    //       classLoading="loading"
    //       classEditing={"riek-text-input-field-editing"}
    //       isDisabled={false} />
    //   );
    // }
    return (
      <Table>
        <tbody>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Field Label</span>
            </td>
            <td className="fieldvalue">
              <span className="text-blue60b">{columnDetail.attrs.label}</span>
            </td>
          </tr>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Field type</span>
            </td>
            <td className="fieldvalue">
              <span className="text-blue60b">{columnDetail.type}</span>
            </td>
          </tr>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Description</span>
            </td>
            <td className="fieldvalue">
              <span>
                <RIEInput
                  value={columnDetail.attrs.userDefined.description}
                  defaultValue={ this.props.updateOk ? "Click to enter description": "No description" }
                  change={this.updateCallback}
                  propName={"field-description-" + columnDetail.name}
                  className={"riek-text-input-field"}
                  classLoading="loading"
                  classInvalid="invalid"
                  classEditing={"riek-text-input-field-editing"}
                  isDisabled={ this.props.updateOk ? false: true } />
                { this.props.updateOk && <>&nbsp;<MdEdit /></> }
              </span>
            </td>
          </tr>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Nullable?</span>
            </td>
            <td className="fieldvalue">
              <span className="text-blue60b">{(columnDetail.attrs.isNullable ? "YES" : "NO")}</span>
            </td>
          </tr>
          {recordTypesElem &&
            <tr>
              <td className="fieldname">
                <span className="text-size-h5 text-red30b">Applicable Record Types</span>
              </td>
              <td className="fieldvalue">
                {recordTypesElem}
              </td>
            </tr>
          }
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Constraints</span>
            </td>
            <td className="fieldvalue">
              <span>
                <RIEInput
                  value={columnDetail.attrs.userDefined.constraints}
                  defaultValue={ this.props.updateOk ? "Click to enter text" : "Not defined" }
                  change={this.updateCallback}
                  propName={"field-constraints-" + columnDetail.name}
                  className={"riek-text-input-field"}
                  classLoading="loading"
                  classInvalid="invalid"
                  classEditing={"riek-text-input-field-editing"}
                  isDisabled={ this.props.updateOk ? false: true } />
                { this.props.updateOk && <>&nbsp;<MdEdit /></> }
              </span>
            </td>
          </tr>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Source</span>
            </td>
            <td className="fieldvalue">
              <span>
                <RIESelect
                  value={columnDetail.attrs.userDefined.source ? columnDetail.attrs.userDefined.source : this.state.sources[0]}
                  className={""}
                  options={this.state.sources}
                  change={this.updateCallback}
                  classLoading="loading"
                  propName={"field-source-" + columnDetail.name}
                  isDisabled={ this.props.updateOk ? false: true } />
                  { this.props.updateOk && <>&nbsp;<MdEdit /></> }
              </span>
            </td>
          </tr>
          <tr>
            <td className="fieldname">
              <span className="text-size-h5 text-red30b">Tags</span>
            </td>
            <td className="fieldvalue">
              {tagsElem}
              { this.props.updateOk && <>&nbsp;<MdEdit /></> }
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderCommentCards() {
    // console.log("*** I am inside renderCommentCards()");
    let cardStyle = { backgroundColor: 'white', padding: '1rem', margin: '1rem' };
    let items = this.state.comments;
    // console.log("*** comment items = " + JSON.stringify(items));
    const cards = items.map((item, i) => {
      return (
        <div key={i} style={cardStyle}>
          <h5>{item.name} ({item.netid}) &mdash; <em>{item.timestamp}</em></h5>
          <p>{item.text}</p>
        </div>
      );
    });
    return cards;
  }

  renderComments() {
    // console.log("*** I am inside renderComments()");
    let containerStyle = { paddingTop: '2rem', paddingBottom: '2rem', background: 'rgb(248, 245, 236)', position: 'relative', overflowY: 'auto', minHeight: '50px', maxHeight: '400px' };
    return (
      <div style={containerStyle}>
        { this.renderCommentCards() }
      </div>
    );
  }

  // render main body of object display
  render() {
    if (this.state.isLoading) {
      return (
        <div className="Tables">
          <h3>{this.props.match.params.name}</h3>
        </div>
      );
    } else {
      // get object record types
      let objectRecordTypes = [];
      if (this.state.parameters.recordtypes.length > 0) {
        objectRecordTypes = this.state.parameters.recordtypes.split(",").map(
          (rectype) => {
            return ({
              value: rectype,
              label: rectype
            });
          }
        );
      }
      // get tags for object
      let tagsElem = null;
      if (this.state.parameters.tags.length === 0) {
        tagsElem = (
          <RIEInput
            value=""
            defaultValue = { this.props.updateOk ? "Click to enter comma-separated tags" : "No tags" }
            change={this.updateCallback}
            propName="obj-tags-string"
            className={"riek-text-input"}
            classEditing={"riek-text-input-editing"}
            validate={_.isString}
            classLoading="loading"
            classInvalid="invalid"
            isDisabled={ this.props.updateOk ? false : true } />
        );
      } else {
        let tag_array = [];
        if (Array.isArray(this.state.parameters.tags)) {
          tag_array = this.state.parameters.tags;
        } else {
          let _tags = this.state.parameters.tags.split(',');
          for(var i = 0; i < _tags.length; i++) {
            let clean_text = _tags[i].replace(/^\s*/, "").replace(/\s*$/, "");
            tag_array.push(clean_text);
          }
        }
        tagsElem = (
          <RIETags
            value={tag_array}
            change={this.updateCallback}
            maxTags={20}
            minTags={0}
            propName="obj-tags-array"
            placeholder="New"
            className={"tags"}
            classLoading="loading"
            classEditing={"riek-text-input-editing"}
            isDisabled={ this.props.updateOk ? false : true } />
        );
      }

      // get comments for object
      let commentsElem = null;
      if (this.state.comments.length > 0) {
        // console.log("*** There are comments!");
        commentsElem = this.renderComments();
      }

      // get column detail
      let columns = this.state.columns.map(
        (col) => {
          let name = col.name;
          let type = col.type;
          let attrs = col.attrs;
          return (
            <AccordionItem key={name}>
              <AccordionItemTitle>
                <div style={{ verticalAlign: "true"}}>
                  <h4>{col.name}</h4>
                </div>
              </AccordionItemTitle>
              <AccordionItemBody>
                {this.renderColumnDetail({name: name, type: type, attrs: attrs, objectRecordTypes: objectRecordTypes})}
              </AccordionItemBody>
            </AccordionItem>
          );
        }
      );

      return (
        <div className="Tables">
            <div className="d-flex">
              <h3 className="margin-align-middle"><u>{this.state.name}</u></h3>
              <div className="ml-auto margin-align-middle">
                {
                  this.props.updateOk ?
                    <Toggle
                      onClick={this.onActiveToggle}
                      on="ACTIVE"
                      off="INACTIVE"
                      size="xs"
                      onstyle="success"
                      offstyle="warning"
                      handlestyle="primary"
                      active={this.state.parameters.active === "Y"}
                    />
                  :
                    <span class={`label ${ this.state.parameters.active === "Y" ? "label-success" : "label-warning" } text-size-h4`}>
                      { this.state.parameters.active === "Y" ? "ACTIVE" : "INACTIVE" }
                    </span>
                }
              </div>
            </div>
            <span className="text-size-h4 text-ash">Object Label:</span>&nbsp;<span className="text-sage"><strong>{this.state.parameters.label}</strong></span>
            <hr/>
            <span className="text-size-h4 text-ash">Description:</span>&nbsp;
            <RIEInput
              value={this.state.description}
              defaultValue = { this.props.updateOk ? "Click to enter object description": "No description" }
              change={this.updateCallback}
              propName={"obj-description"}
              className={"riek-text-input"}
              validate={_.isString}
              classLoading="loading"
              classEditing="riek-text-input-editing"
              classInvalid="invalid"
              isDisabled={ this.props.updateOk ? false : true } />
            { this.props.updateOk && <>&nbsp;<MdEdit /></> }
            <hr/>
            <span className="text-size-h4 text-ash">Record Types:</span>&nbsp;
            <span className="text-sage"><strong>{this.state.parameters.recordtypes}</strong></span>
            <hr/>
            <Picklists objname={this.state.name} />
            <span className="text-size-h4 text-ash">Tags:</span>&nbsp;<span>{tagsElem}{ this.props.updateOk && <>&nbsp;<MdEdit /></> }</span>
            <hr/>
            <div style={ {verticalAlign: 'middle'} }>
              <span className="text-size-h4 text-ash">Comments:</span>
              <br/>
              <form onSubmit={(event) => { event.preventDefault(); this.postComment(this.state.name, this.state.objectComment); }}>
                <input className="riek-text-input-editing" style={ {width: '100%'} } type="text" value={this.state.objectComment} onChange={this.handleCommentChange} />
                <br/>
                <input className="btn btn-sm btn-primary" type="submit" value="Add Comment" />
              </form>
            </div>
            {commentsElem}
            <hr/>
            <span className="text-size-h4 text-ash">Fields</span>
            <IconContext.Provider value={{ style: { verticalAlign: 'top' }, color: "#0B234B" }}>
              <Accordion onChange={this.accordionChanged}>
              {columns}
              </Accordion>
            </IconContext.Provider>
        </div>
      );
    }
  }
}

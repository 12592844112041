import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import { Table } from "react-bootstrap";
import { IconContext } from "react-icons";
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import { Base64 } from 'js-base64';
import pako from 'pako';

import _ from 'lodash';

export default class Picklists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordTypes: {}
    };
  }

  async componentDidMount() {
    try {
      const picklists_res = await this.getPicklists();
      let recordTypes = {};
      picklists_res.picklists.forEach(function(pl) {
        if (pl.rectype in recordTypes) {
          recordTypes[pl.rectype].push({
            name: pl.field,
            picklistData: JSON.parse(this.picklistToJSON(pl.picklist_json))
          });
        } else {
          recordTypes[pl.rectype] = [
            {
              name: pl.field,
              picklistData: JSON.parse(this.picklistToJSON(pl.picklist_json))
            }
          ];
        }
      }, this);
      // console.log("*** record types = " + JSON.stringify(recordTypes));
      this.setState({ recordTypes });
    } catch (e) {
      alert(e);
    }
    // console.log("*** inside componentDidMount: state = " + JSON.stringify(this.state));
  }

  componentWillMount() {
    this.getPicklists = this.getPicklists.bind(this);
    this.picklistToJSON = this.picklistToJSON.bind(this);
    this.renderFieldDetail = this.renderFieldDetail.bind(this);
    this.renderFields = this.renderFields.bind(this);
  }

  picklistToJSON(zlibB64Data) {
    let binData = atob(zlibB64Data);
    let charData = binData.split('').map(function (x) { return x.charCodeAt(0); });
    let zlibData = new Uint8Array(charData);
    return pako.inflate(zlibData, { to: 'string' });
  }

  getPicklists() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("picklists", `/picklists/${this.props.objname}`, myInit)
    })
    .catch(error => {
      console.log("Error in Auth.currentSession: " + error.response);
      return false;
    });
  }

  renderFieldDetail(fieldDetail) {
    // console.log("*** field detail = " + JSON.stringify(fieldDetail));
    let controller = (fieldDetail.controller) ? fieldDetail.controller : "n/a";
    let fieldValues = fieldDetail.values.map(
      (fv) => {
        let validFor = [];
        if (fv.validFor) {
          validFor = fv.validFor.map(
            (vf) => {
              return(
                <span className="label label-info">{vf}</span>
              );
            }
          );
        }
        if (validFor.length === 0) {
          validFor = (
            <span className="label label-muted">n/a</span>
          );
        }
        return (
          <tr>
            <td>
              <span className="label label-primary">{(fv.label) ? fv.label : fv.value}</span>
            </td>
            <td>
              {validFor}
            </td>
          </tr>
        );
      }
    );
    return (
      <div>
        <Table>
          <tbody>
            <tr>
              <td className="fieldname">
                <span className="text-size-h5 text-red30b">Controlling Field:</span>
              </td>
              <td className="fieldvalue">
                <span className="text-blue60b">{controller}</span>
              </td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <th>
                Field Value
              </th>
              <th>
                Valid For
              </th>
            </tr>
          </thead>
          <tbody>
            {fieldValues}
          </tbody>
        </Table>
      </div>
    );
  }

  renderFields(recordType) {
    let fields = this.state.recordTypes[recordType].map(
      (field) => {
        return (
          <AccordionItem key={recordType + '-' + field.name}>
            <AccordionItemTitle>
              <div style={{ verticalAlign: "true"}}>
                <h4>{field.name}</h4>
              </div>
            </AccordionItemTitle>
            <AccordionItemBody>
              {this.renderFieldDetail(field.picklistData)}
            </AccordionItemBody>
          </AccordionItem>
        );

      }
    );
    return (
      <Accordion>
      {fields}
      </Accordion>
    );
  }

  render() {
    if (Object.keys(this.state.recordTypes).length > 0) {
      let recordTypes = Object.keys(this.state.recordTypes).map(
        (rt) => {
          return (
            <AccordionItem key={rt}>
              <AccordionItemTitle>
                <div style={{ verticalAlign: "true"}}>
                  <h4>{(rt === '__default') ? 'object defaults' : rt}</h4>
                </div>
              </AccordionItemTitle>
              <AccordionItemBody>
                <span className="text-size-h4 text-ash">Fields</span>
                {this.renderFields(rt)}
              </AccordionItemBody>
            </AccordionItem>
          );
        }
      );
      return (
        <div>
          <span className="text-size-h4 text-ash">Picklist Field Values by Record Type</span>
            <Accordion>
            {recordTypes}
            </Accordion>
            <hr/>
        </div>
      );
    } else {
      return null;
    }
  }
}

const dev = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lc3v6ckkh1.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_zFqLU9JBV",
    APP_CLIENT_ID: "22c9tjdmaqkvo0rqcisqrvv5s2",
    DOMAIN: "crm-dict.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "https://crmdict.test.sia.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    UPDATE_GROUP: "arizona.edu:dept:uits:crm:datadictionary:update-users"
  }
};

const prod = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lrsg7pdei4.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_aaWKEbq2y",
    APP_CLIENT_ID: "4m64eoslaqhsh588jeen078gi3",
    DOMAIN: "ua-trellis-crmdict.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "https://crmdict.trellis.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    UPDATE_GROUP: "arizona.edu:dept:uits:crm:datadictionary:update-users"
  }
};

const local = {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lrsg7pdei4.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_aaWKEbq2y",
    APP_CLIENT_ID: "4m64eoslaqhsh588jeen078gi3",
    DOMAIN: "ua-trellis-crmdict.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "http://localhost:4200/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    UPDATE_GROUP: "arizona.edu:dept:uits:crm:datadictionary:update-users"
  }
};

const configMap = {
  'production': prod,
  'local': local,
  'development': dev
};

const config = configMap[process.env.REACT_APP_STAGE];

export default {
  apiGateway: config.apiGateway,
  cognito: config.cognito,
  admin: config.admin
};
import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem, Alert, Badge, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth, API } from 'aws-amplify';
import { FaTags, FaClock} from 'react-icons/fa';
import { IconContext } from "react-icons";
import Select from 'react-select';
import Spinner from 'react-spinkit';
import ReactTooltip from 'react-tooltip';

// import { WithContext as ReactTags } from 'react-tag-input';
import _ from 'lodash';

import "../css/Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.setTagFilter = this.setTagFilter.bind(this);
    this.setSearchFilter = this.setSearchFilter.bind(this);
    this.tables = this.tables.bind(this);

    this.state = {
      isLoading: true,
      activeFilter: "Y",
      searchFilter: "",
      tags: [],
      tagFilter: [],
      tables: []
    };
  }

  async componentWillReceiveProps(props) {
    if (this.props !== props) {
      // console.log("*** inside componentWillReceiveProps()");
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (props.authn !== 'signedIn') {
        return;
      }
      this.setState({ isLoading: true });
      await this.refreshTables();
      this.setState({isLoading: false});
    }
//    console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // console.log("*** inside componentDidMount()");
    this.setState({isLoading: true});
    await this.refreshTables();
    this.setState({isLoading: false});
 //    console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  async refreshTables() {
    try {
      const tables = await this.tables();
      let _tables = _.filter(tables, function(t) { 
        if (!t.name.match(/(__e|__mdt|changeevent|history|share|feed)$/)) {
          return true;
        }
        return false;
      });
      this.setState({ tables: _tables });
      // create sorted/uniqued array of all table tags
      let tags = [];
      _tables.map(
        (tbl) => {
          if (tbl.parameters.tags.length > 0) {
            tags = tags.concat(tbl.parameters.tags.split(','));
          }
        }
      );
      tags = _.uniq(tags.sort());
      let tagsList = tags.map(
        (tag) => {
          return ({
            value: tag,
            label: tag
          });
        }
      );
      this.setState({ tags: tagsList });
    } catch (e) {
      alert(e);
    }
  }

  setSearchFilter(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  setActiveFilter(filter) {
    this.setState({activeFilter: filter});
  }

  setTagFilter(opt) {
    let filterTags = opt.map(
      (tag) => {
        return (tag.value);
      }
    );
    this.setState({tagFilter: filterTags});
  }

  searchComparator(tbl, searchVal) {
    // check if substring of name, label, or desc of tbl matches searchVal
    if (
      tbl.name.toLowerCase().indexOf(searchVal.toLowerCase().replace(/"/g, '')) > -1 ||
      tbl.description.toLowerCase().indexOf(searchVal.toLowerCase().replace(/"/g, '')) > -1 ||
      tbl.parameters.label.toLowerCase().indexOf(searchVal.toLowerCase().replace(/"/g, '')) > -1
    ) {
      return true;
    }
    return false;
  }

  tables() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("tables", "/tables", myInit);
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error.response);
      return [];
    });
  }

  renderTablesList(tables) {
  //  console.log("*** in renderTablesList");
//    console.log("----> tables = " + JSON.stringify(tables));
    let _tables = tables;
    let activeFilter = this.state.activeFilter;
    // filter by active state of object
    if (this.state.activeFilter !== "A") {
      _tables = _.filter(tables, function(t) { return (t.parameters.active === activeFilter); });
    }
    // filter by tags
    if (this.state.tagFilter.length > 0) {
      _tables = _.filter(_tables, function(t) { return (_.intersection(t.parameters.tags.split(','), this.state.tagFilter).length > 0 ); }.bind(this));
    }
    // filter by search terms
    if (this.state.searchFilter.length > 0) {
      _tables = _.filter(_tables, function(t) { return (_.intersectionWith([t], this.state.searchFilter.match(/(?:[^\s"]+|"[^"]*")+/g), this.searchComparator).length > 0 ); }.bind(this));
      // _tables =_.filter(_tables, function(t) {
      //   return (_.intersectionBy([t.name], this.state.searchFilter.split(' '), _.toLower).length > 0); 
      // }.bind(this));
    }

    return _tables.map(
      (table) => {
        let desc = '(No Description)';
        let tags = [];
        let active = (table.parameters.active === 'Y') ? true : false;

        if (table.description.length > 0) {
          desc = table.description;
        }
        // if (table.parameters.tags.length > 0) {
        //   let tag_array = table.parameters.tags.split(',');
        //   for(var i = 0; i < tag_array.length; i++) {
        //       let clean_text = tag_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
        //       tags[i] = {id: i, text: clean_text};
        //   }
        // }
        if (table.parameters.tags.length > 0) {
          let tag_array = table.parameters.tags.split(',');
          for(var i = 0; i < tag_array.length; i++) {
              let clean_text = tag_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
              tags.push(<Badge key={"tag" + i} bsStyle="primary">{clean_text}</Badge>);
          }
        }
        return (
          <LinkContainer
            key={table.name}
            to={`/tables/${table.name}`}
          >
            <ListGroupItem>
              <div className="row mb-2">
                <div className="col-xs-12 col-md-8">
                  <span className="text-size-h4 text-blue30w">{table.name}</span>
                </div>
                <div className="col-xs-6 col-md-4">
                  <span className={`label label-${active ? "success" : "warning"} ml-0`}>{active ? "ACTIVE" : "INACTIVE"}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <small className="text-silver-dark">{table.parameters.label}</small>
                </div>
                <div className="col-xs-6 col-md-4">
                  <div>
                    <div>
                      <FaTags />
                      {tags}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8 py-2">
                  <span className="text-mesa text-size-h6">{desc}</span>
                </div>
                <div className="col-xs-6 col-md-4 py-2">
                    <div>
                      <FaClock />&nbsp;&nbsp;
                      <span className="text-cactus text-size-h6">
                        {"Updated: " + new Date(table.modifytime*1000).toLocaleString()}
                      </span>
                    </div>
                </div>
              </div>
            </ListGroupItem>
          </LinkContainer>
        );
      }
    );
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Trellis Data Dictionary</h1>
        <p>The Trellis Data Dictionary provides a central location to reference and
        manage Salesforce object and field definitions.</p>
      </div>
    );
  }

  renderTables() {
  //  console.log("*** in renderTables");
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '0',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: state.isFocused ? '#9eabae' : '#cbd1e0',
        height: '38px',
        boxShadow: state.isFocused ? 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(158,171,174,.6)' : 'inset 0 1px 1px rgba(0,0,0,.075)',
        color: '#49595e',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
        ':hover': {
          borderColor: '#9eabae',
        },
        ':focus': {
          zIndex: '3',
        }
      })
    };
    return (
      <div className="tables">
        <PageHeader>Salesforce Objects</PageHeader>
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-4 p-2">
              <div className="form-group">
                <label for="tagFilter">Filter By Tags</label>
                <Select
                    id="tagFilter"
                    styles={customStyles}
                    isMulti={true}
                    onChange={this.setTagFilter}
                    options={this.state.tags}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Filter by tags..."
                />
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div className="form-group">
                <label for="searchFilter">Search&nbsp;<span data-tip='' data-for='searchTip' className="text-oasis text-size-h5">&#9432;</span></label>
                <ReactTooltip place="top" type="dark" effect="float" id='searchTip'>
                  <p><strong>Unified Search</strong></p>
                  <p>You can search on one or more terms (separated by spaces).</p>
                  <p>Search will be performed against object names, labels, and descriptions.</p>
                  <p>Use double quotes (&quot;) to search for a phrase.</p>
                </ReactTooltip>
                <input type="text" className="form-control" name="searchFilter" id="searchFilter" placeholder="Begin typing to search..." onChange={this.setSearchFilter} />
              </div>
            </div>
            <div className="p-2 ml-md-auto align-self-center">
              <ToggleButtonGroup name="radio" type="radio" defaultValue={"Y"} onChange={this.setActiveFilter}>
                <ToggleButton value={"A"}>
                  ALL
                </ToggleButton>
                <ToggleButton value={"Y"}>
                  ACTIVE
                </ToggleButton>
                <ToggleButton value={"N"}>
                  INACTIVE
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="row">
            <IconContext.Provider value={{ style: { verticalAlign: 'middle' }, color: "#0B234B" }}>
            <ListGroup>
              {!this.state.isLoading && this.renderTablesList(this.state.tables)}
            </ListGroup>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Trellis Data Dictionary</h1>
        <p>The Trellis Data Dictionary provides a central location to reference and
        manage Salesforce object and field definitions.</p>
        <Alert bsStyle="danger">You do not have the appropriate permissions to use this application.</Alert>
      </div>
    );
  } 
  
  renderLoading() {
    return (
      <div>
        <PageHeader>Salesforce Objects</PageHeader>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.authz && !this.state.isLoading) && this.renderTables()}
        {(this.props.authn === 'signedIn' && this.props.authz && this.state.isLoading) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}

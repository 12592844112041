import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Button } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import CustomOAuthButton from './CustomOAuthButton';
import Amplify, {Auth, Hub} from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import config from "./config";
import {authz} from "./authorization";
import Routes from "./Routes";

const oauth = {
  domain: config.cognito.DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config.cognito.REDIRECT_SIGNIN,
  redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
  responseType: 'code' // or token
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "tables",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "comments",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "picklists",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "authz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});
// Auth.configure({
//   oauth: oauth,
//   region: config.cognito.REGION,
//   userPoolId: config.cognito.USER_POOL_ID,
//   userPoolWebClientId: config.cognito.APP_CLIENT_ID
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.refreshAuthNZ = this.refreshAuthNZ.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.state = {
      authn: 'loading',
      authz: false,
      updateOk: false,
      groups: []
    };
    // let the Hub module listen on Auth events
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  componentDidMount() {
    // console.log('on component mount');
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      authz().then(res => {
        this.setState({
          authn: 'signedIn',
          authz: res,
          updateOk: false,
          groups: []
        }, () => Auth.currentUserInfo().then(res => {
          // console.log(res);
          if (res.attributes.hasOwnProperty('custom:ismemberof')) {
            this.updateGroups(res.attributes['custom:ismemberof']);
            }
        }));
      });
    }).catch(e => {
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false,
          updateOk: false,
          groups: []
        }
      });
    // console.log('not signed in: ' + e.response);
    });
  }

  onAuthEvent(payload) {
    // The Auth module will emit events when user signs in, signs out, etc
    // console.log("*** auth payload:");
    // console.log(JSON.stringify(payload));
    switch (payload.event) {
      case 'signIn':
        authz()
          .then(res => {
            this.refreshAuthNZ({
              data: {
                authn: 'signedIn',
                authz: res,
                updateOk: false,
                groups: []
              }
            });
            Auth.currentUserInfo().then(res => {
              // console.log(res);
              if (res.attributes.hasOwnProperty('custom:ismemberof')) {
                this.updateGroups(res.attributes['custom:ismemberof'])
              }
            });
          });
        break;
      case 'signIn_failure':
        this.refreshAuthNZ({
          data: {
            authn: 'signIn',
            authz: false,
            updateOk: false,
            groups: []
          }
        });
        break;
      default:
        break;
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false,
          updateOk: false,
          groups: []
        }
      });
    }).catch(e => {
      console.log(e);
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false,
          updateOk: false,
          groups: []
        }
      });
    });
  }

  // refresh user groups
  updateGroups(groups) {
    // Cognito returns groups as string "[grp1, grp2, ..., grpN]"
    let _groups = groups.slice(1, -1).split(', ');
    let updateOk = false;
    const updateGroup = config.admin.UPDATE_GROUP;
    if (_groups.includes(updateGroup)) {
      updateOk = true;
    }
    console.log("groups: " + _groups);
    console.log("update group:" + updateGroup);
    console.log("updateOk: " + updateOk);
    this.setState({
      groups: _groups,
      updateOk: updateOk
    });
  }

  // refresh user state
  refreshAuthNZ(res) {
    this.setState({authn: res.data.authn, authz: res.data.authz})
  }

  render() {
    const { authn } = this.state;
    return (
      authn !== 'loading' &&
      <div className="App container">
        <ToastContainer autoClose={2000} />
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Trellis Data Dictionary</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Navbar.Form pullRight>
              {authn === 'signIn' && <CustomOAuthButton/>}
              {authn === 'signedIn' && <Button bsStyle="primary" onClick={this.signOut}>Logout</Button>}
            </Navbar.Form>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={{authn: this.state.authn, authz: this.state.authz, updateOk: this.state.updateOk}} />
      </div>
    );
  }
}

export default App;
